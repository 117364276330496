export const getEthChainInfo = () => {
  let chainId = 1;
  let rpcUrl = 'https://mainnet.infura.io/v3/7f81c975a0714f479ba553ebd83f1362/';
  // // if (/\/\/farm.deor.io/.test(href)) {
  //      chainId = 1;
  //      rpcUrl = 'https://mainnet.infura.io/v3/e707b58edfd7437cbb6e9079c259eda7/';
  //      ethscanType = '';
  // // }
  return {
    chainId,
    rpcUrl,
  }
};
